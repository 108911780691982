<template>
	<div class="pts">
		<div class="pt-cont" :class="{'mr20':index%2==0}" v-for="(i,index) of businessItem" :key="index">
			<div class="pt-title font-weight700" >{{i.fApplicationText}}</div>
			<div @click="detail(j.fApplicationName)" class="pt-contb" v-for="(j,indexs) of i.children" :key="indexs">
				<div class="pt-contbc">
					<div style="text-align: center;" >{{j.fApplicationText}}</div>
				</div>

			</div>
		</div>
		
		
	</div>
</template>

<script>
	import parmsd from '@/views/business/params'
	export default {
		data() {
			return {
				businessItem:[]
			}
		},
		created() {
			this.getMenList();
		},
		methods: {
			detail(path) {
				//跳转链接
				this.$router.push({
					path: path,
					query: ''
				});
			},
			getMenList(){
				
				this.ApiRequestPostNOMess('api/mall/sys/application/get-user-app-all-child-by-app-name',{
					  "fApplicationName": "我的业务",
					  "appTypeId": this.fAppTypeID,
					  "fSubsystemID":120
				}).then(
					res=>{
						let menArr = [];
						menArr = this.toMenTree(res.obj);
						let menArr2  = [];
						menArr2 = menArr[0].children;
						this.businessItem = menArr2; 
					},
					error =>{
						
					}
				);
			},
		
		}

	}
</script>

<style lang="scss" scoped>
	.pts {
		padding-top: 15px;
		min-width: 1100px;
		margin: 0 auto;
		max-width: 1100px;
	}

	.pt-title {
		font-size: 16px;
		margin-bottom: 6px;
		
		
	}

	.pt-cont {
		padding-left: 20px;
		padding-right: 20px;
		float: left;
		min-width: 500px;
		border-radius: 5px;
		background-color: #f2f8ff;
		margin-bottom: 20px;
		min-height: 130px;
		max-width: 500px;
		padding-top: 15px;
		padding-bottom: 20px;
	}

	.mr20 {
		margin-right: 20px;
	}

	.pt-contb {
		cursor: pointer;
		margin: 7.5px 20px 10px 0px;
		width: 80px;
		height: 80px;
		float: left;

	}

	.pt-contbc {
		width: 100%;
		height: 100%;
		background-color: #fff;
	}
</style>
